<script setup>
import { useScrollToc } from '@/composables/useScrollToc.js'
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem
} from '@headlessui/vue'
import ChevronUpDownIcon from '@/components/icons/ChevronUpDownIcon.vue'

defineProps({
  options: Array
})
const { currentSection } = useScrollToc('h2[data-toc-header]')

function smoothScroll(event) {
  document.querySelector(event.target.getAttribute('href')).scrollIntoView({
    behavior: 'smooth'
  })
}
</script>


<template>
  <div class="block sm:hidden">
    <Menu as="div" class="inline-block text-left w-full">
      <MenuButton
        class="inline-flex text-[15px] w-full border border-gray-mid-light focus:text-primary focus:border-primary rounded-md
        p-2 text-left justify-between items-center text-medium"
      >
        Su questa pagina
        <ChevronUpDownIcon
          class="h-5 w-5"
          aria-hidden="true"
        />
      </MenuButton>
      <transition
      >
      <MenuItems
        class="right-0 mt-2 w-full rounded-md bg-gray-light focus:outline-none"
      >
        <div class="px-1 py-1 text-sm">
          <MenuItem v-for="option in options" :key="option.slug">
            <button
              @click.prevent="smoothScroll"
              :class="{'border-l-2 text-primary' : option.slug === currentSection }"
              :href="`#${option.slug}`" class="px-2 hover:text-primary block"
            >{{ option.label }}</button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
    </Menu>
  </div>
  <div class="text-sm sm:sticky top-40 hidden sm:block font-family-raleway font-medium">
    <ol>
      <li v-for="option in options" :key="option.slug">
        <a
          @click.prevent="smoothScroll"
          :class="option.slug === currentSection ? 'text-primary border-primary' : 'border-transparent'"
          :href="`#${option.slug}`" class="px-3 py-1 hover:text-primary hover:border-primary border-l-2 block"
        >{{ option.label }}</a>
      </li>
    </ol>
  </div>
</template>

<style lang="scss" scoped>
  button[aria-expanded="true"] {
    border-color: var(--primary);
    color: var(--primary);
  }
</style>