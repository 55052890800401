const TWITTER_SHARE_BASE = 'https://twitter.com/intent/tweet'
const FACEBOOK_SHARE_BASE = 'https://www.facebook.com/sharer/sharer.php'
const LINKEDIN_SHARE_BASE = 'https://www.linkedin.com/shareArticle/'

function buildArticleUrl (article, source) {
  const config = useRuntimeConfig().public
  const articleUrl = new URL(`blog/articles/${article.attributes.slug}/`, config.siteUrl)
  articleUrl.searchParams.set('utm_medium', 'social')
  articleUrl.searchParams.set('utm_campaign', 'share')
  articleUrl.searchParams.set('utm_source', source)
  return articleUrl.toString()
}

export const useShareUrl = (article) => {

  const twitterShareUrl = computed(() => {
    const url = new URL(TWITTER_SHARE_BASE)
    url.searchParams.set('url', buildArticleUrl(article, 'twitter'))
    url.searchParams.set('text', article.attributes.title)
    url.searchParams.set('via', 'MessageNet_it')
    
    return url.toString()
  })

  const facebookShareUrl = computed(() => {
    const url = new URL(FACEBOOK_SHARE_BASE)
    url.searchParams.set('u', buildArticleUrl(article, 'facebook'))

    return url.toString()
  })

  const linkedInShareUrl = computed(() => {
    const url = new URL(LINKEDIN_SHARE_BASE)
    url.searchParams.set('url', buildArticleUrl(article, 'linkedin'))
    url.searchParams.set('title', article.attributes.title)
    url.searchParams.set('mini', true)

    return url.toString()
  })

  return { twitterShareUrl, facebookShareUrl, linkedInShareUrl }
}