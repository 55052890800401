<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>linkedin_icon</title>
    <g id="JellyFox-" stroke="none" stroke-width="1" fill="#currentColor" fill-rule="evenodd">
        <g id="Voce" transform="translate(-1196.000000, -4353.000000)" fill="#currentColor">
            <g id="Footer" transform="translate(-2.000000, 3904.000000)">
                <g id="linkedin_v1" transform="translate(1198.000000, 449.000000)">
                    <path d="M4.72289157,0 C2.11451058,0 0,2.1490332 0,4.8 L0,19.2 C0,21.8509668 2.11451058,24 4.72289157,24 L18.8915663,24 C21.4999473,24 23.6144578,21.8509668 23.6144578,19.2 L23.6144578,4.8 C23.6144578,2.1490332 21.4999473,0 18.8915663,0 L4.72289157,0 Z M2.36144578,4.8 C2.36144578,3.4745166 3.41870107,2.4 4.72289157,2.4 L18.8915663,2.4 C20.1957568,2.4 21.253012,3.4745166 21.253012,4.8 L21.253012,19.2 C21.253012,20.5254834 20.1957568,21.6 18.8915663,21.6 L4.72289157,21.6 C3.41870107,21.6 2.36144578,20.5254834 2.36144578,19.2 L2.36144578,4.8 Z M8.26506024,10.8 C8.26506024,10.1372583 7.7364326,9.6 7.08433735,9.6 C6.4322421,9.6 5.90361446,10.1372583 5.90361446,10.8 L5.90361446,18 C5.90361446,18.6627417 6.4322421,19.2 7.08433735,19.2 C7.7364326,19.2 8.26506024,18.6627417 8.26506024,18 L8.26506024,10.8 Z M8.85542169,6.6 C8.85542169,7.59411255 8.06248022,8.4 7.08433735,8.4 C6.10619448,8.4 5.31325301,7.59411255 5.31325301,6.6 C5.31325301,5.60588745 6.10619448,4.8 7.08433735,4.8 C8.06248022,4.8 8.85542169,5.60588745 8.85542169,6.6 Z M11.8072289,9.59999998 C12.2086747,9.59999998 12.5628916,9.804 12.7754217,10.1136 C13.3839704,9.77652508 14.0659778,9.59999998 14.7590361,9.59999998 C17.3093976,9.59999998 18.8915663,11.9112 18.8915663,13.8852 L18.8915663,18 C18.8915663,18.6627417 18.3629386,19.2 17.7108434,19.2 C17.0587481,19.2 16.5301205,18.6627417 16.5301205,18 L16.5301205,13.884 C16.5301205,12.9624 15.7508434,11.9988 14.7590361,11.9988 C14.1403373,11.9988 13.4566988,12.3408 12.9879518,13.1544 L12.9879518,18 C12.9879518,18.6627417 12.4593242,19.2 11.8072289,19.2 C11.1551337,19.2 10.626506,18.6627417 10.626506,18 L10.626506,10.8 C10.626506,10.1372583 11.1551337,9.59999998 11.8072289,9.59999998 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </g>
  </svg>
</template>