<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>facebook_icon</title>
      <g id="JellyFox-" stroke="none" stroke-width="1" fill="#currentColor" fill-rule="evenodd">
          <g id="Voce" transform="translate(-1270.000000, -4353.000000)" fill="#currentColor">
              <g id="Footer" transform="translate(-2.000000, 3904.000000)">
                  <g id="facebook_v1" transform="translate(1272.385542, 449.000000)">
                      <path d="M0,4.8 C0,2.1490332 2.11451058,0 4.72289157,0 L18.8915663,0 C21.4999473,0 23.6144578,2.1490332 23.6144578,4.8 L23.6144578,19.2 C23.6144578,21.8509668 21.4999473,24 18.8915663,24 L4.72289157,24 C2.11451058,24 0,21.8509668 0,19.2 L0,4.8 Z M4.72289157,2.4 C3.41870107,2.4 2.36144578,3.4745166 2.36144578,4.8 L2.36144578,19.2 C2.36144578,20.5254834 3.41870107,21.6 4.72289157,21.6 L11.8072289,21.6 L11.8072289,13.2 L10.626506,13.2 C9.97441078,13.2 9.44578313,12.6627417 9.44578313,12 C9.44578313,11.3372583 9.97441078,10.8 10.626506,10.8 L11.8072289,10.8 L11.8072289,9 C11.8072289,6.68040405 13.6574257,4.8 15.939759,4.8 L16.6481928,4.8 C17.300288,4.8 17.8289157,5.3372583 17.8289157,6 C17.8289157,6.6627417 17.300288,7.2 16.6481928,7.2 L15.939759,7.2 C15.4700383,7.2 15.019556,7.38964231 14.6874133,7.72720779 C14.3552705,8.06477328 14.1686747,8.52261032 14.1686747,9 L14.1686747,10.8 L16.6481928,10.8 C17.300288,10.8 17.8289157,11.3372583 17.8289157,12 C17.8289157,12.6627417 17.300288,13.2 16.6481928,13.2 L14.1686747,13.2 L14.1686747,21.6 L18.8915663,21.6 C20.1957568,21.6 21.253012,20.5254834 21.253012,19.2 L21.253012,4.8 C21.253012,3.4745166 20.1957568,2.4 18.8915663,2.4 L4.72289157,2.4 Z" id="Shape"></path>
                  </g>
              </g>
          </g>
      </g>
  </svg>
</template>