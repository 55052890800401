export const useFooterCTA = (content) => {

  const DEFAULT_COLOR = 'primary'
  const DEFAULT_IMAGE_POSITION = 'right'

  const footerBgClassesMap = {
    primary: 'bg-primary',
    white: 'bg-white'
  }
  const footerBgClasses = footerBgClassesMap[content.backgroundColor || DEFAULT_COLOR]

  let imagePositionClasses, textPositionJustificationClass
  switch (content.imagePosition) {
    case 'top':
      imagePositionClasses = 'md:top-0 md:left-0 md:right-0'
      textPositionJustificationClass = ''
      break
    case 'right':
      imagePositionClasses = 'md:bottom-0 md:right-0 lg:right-100 2xl:right-10'
      textPositionJustificationClass = 'justify-start'
      break
    case 'bottom':
      imagePositionClasses = 'md:bottom-0 md:left-0 md:right-0'
      textPositionJustificationClass = ''
      break
    case 'left':
      imagePositionClasses = 'md:bottom-0 md:left-0'
      textPositionJustificationClass = 'justify-end'
      break
  }

  const { textClass, buttonClasses, textAlignmentClasses, flexDirection, hasMobileImage, hasDesktopImage, imageMobile, imageDesktop } = useCTA(content)

  return {
    footerBgClasses,
    textClass,
    buttonClasses,
    textAlignmentClasses,
    flexDirection,
    imagePositionClasses,
    textPositionJustificationClass,
    hasMobileImage,
    hasDesktopImage,
    imageMobile,
    imageDesktop
  }
}