import { ref, onMounted } from 'vue'

export function useScrollToc(selector) {
  const currentSection = ref("")
  
  onMounted(() => {
    const nodes = document.querySelectorAll(selector)
    if (nodes === undefined) {
      return
    }
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting)
        //update current state if the target element intersects 
        //with the intersection observer's root or root margin
        currentSection.value = entry.target.getAttribute("id")
      })
     })

    nodes.forEach((target) => {
      observer.observe(target);
    })

  })
  return { currentSection }
}