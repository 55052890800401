export const useMnetBlogSeo = (article) => {
  const seo = article.attributes.seo
  const config = useRuntimeConfig().public
  const assetsUrl = config.assetsUrl
  const siteUrl = config.siteUrl
  const twitterMeta = seo?.metaSocial.find(s => s.socialNetwork === 'Twitter')
  const facebookMeta = seo?.metaSocial.find(s => s.socialNetwork === 'Facebook')

  const ogTitle = seo?.metaTitle
  const ogDescription = seo?.metaDescription
  const ogImage = seo?.metaImage?.data ? `${assetsUrl + seo?.metaImage?.data.attributes.url}` : ''
  const ogImageWidth = seo?.metaImage?.data?.attributes?.width
  const ogImageHeight = seo?.metaImage?.data?.attributes?.height
  const ogUrl = siteUrl + '/blog/articles/' + article.attributes.slug + '/'
  const tags = article.attributes.tag?.data?.map( tag => tag.attributes.name ).join(', ')

  useSeoMeta({
    title: ogTitle,
    ogTitle: facebookMeta?.title || ogTitle,
    description: ogDescription,
    ogDescription: facebookMeta?.description || ogDescription,
    ogImage: facebookMeta?.image?.data ? `${assetsUrl + facebookMeta?.image.data.attributes.url}` : ogImage,
    keywords: seo?.keywords,
    twitterCard: 'summary_large_image',
    ogImageWidth,
    ogImageHeight,
    ogUrl,
    ogType: 'article',
    author: 'Messagenet',
    twitterTitle: twitterMeta?.title || ogTitle,
    twitterDescription: twitterMeta?.description || ogDescription,
    twitterImage: twitterMeta?.image?.data ? `${assetsUrl + twitterMeta?.image.data.attributes.url}` : ogImage
  })
  useHead({
    meta: [
      { property: "article:published_time", content: article.attributes.updatedAt },
      { property: "og:updated_time", content: article.attributes.updatedAt },
      { name: "keywords", content: tags },
      { property: "article:tag", content: tags },
      { property: "article:author", content: 'Messagenet' }
    ]
  })
}