export const useCTA = (content) => {

const DEFAULT_COLOR = 'primary'
const DEFAULT_ALIGNMENT = 'center'
const bgClassesMap = {
  primary: 'bg-primary rounded-standard',
  white: 'bg-white',
  dark: 'bg-dark rounded-standard'
}
const bgClasses = bgClassesMap[content.backgroundColor || DEFAULT_COLOR]

const textColorMap = {
  primary: 'text-white prose-h1:text-white prose-h2:text-white prose-h3:text-white prose-h4:text-white prose-h5:text-white prose-h6:text-white prose-strong:text-white',
  white: 'text-primary prose-h1:text-primary prose-h2:text-primary prose-h3:text-primary prose-h4:text-primary prose-h5:text-primary prose-h6:text-primary prose-strong:text-primary',
  dark: 'text-white prose-h1:text-white prose-h2:text-white prose-h3:text-white prose-h4:text-white prose-h5:text-white prose-h6:text-white prose-strong:text-white'
}
const textClass = textColorMap[content.backgroundColor || DEFAULT_COLOR]

const buttonClassesMap = {
  primary: 'bg-primary text-white border-white border border-x-2 border-y-1',
  white: 'bg-white text-primary border-primary border border-x-2 border-y-1',
  primaryFull: 'bg-primary text-white',
  whiteOutline: 'bg-transparent text-white border-white border border-x-2 border-y-1'
}
const buttonClasses = buttonClassesMap[content.buttonColor || DEFAULT_COLOR]

const textAlignmentClassesMap = {
  left: 'lg:text-left',
  center: 'lg:text-center',
  right: 'lg:text-right'
}
const textAlignmentClasses = textAlignmentClassesMap[content.alignText || DEFAULT_ALIGNMENT]


let flexDirection, imageOrder, textOrder, imageAnchor

switch(content.imagePosition) {
  case 'top':
    flexDirection = 'lg:flex-col'
    imageOrder = 'lg:order-1'
    textOrder = 'lg:order-2'
    imageAnchor = 'lg:self-center'
    break
  case 'right':
    flexDirection = 'lg:flex-row'
    imageOrder = 'lg:order-2'
    textOrder = 'lg:order-1'
    imageAnchor = 'lg:self-end'
    break
  case 'bottom':
    flexDirection = 'lg:flex-col'
    imageOrder = 'lg:order-2'
    textOrder = 'lg:order-1'
    imageAnchor = 'lg:self-center'
    break
  case 'left':
    flexDirection = 'lg:flex-row'
    imageOrder = 'lg:order-1'
    textOrder = 'lg:order-2'
    imageAnchor = 'lg:self-end'
    break
}

const hasMobileImage = !!content.ctaImageMobile?.data && Object.keys(content.ctaImageMobile?.data?.attributes).length > 0
const hasDesktopImage = !!content.ctaImage?.data && Object.keys(content.ctaImage?.data?.attributes).length > 0
const imageMobile = content.ctaImageMobile?.data?.attributes
const imageDesktop = content.ctaImage?.data?.attributes

  return {
    bgClasses,
    textClass,
    buttonClasses,
    textAlignmentClasses,
    flexDirection,
    imageOrder,
    textOrder,
    imageAnchor,
    imageMobile,
    imageDesktop,
    hasMobileImage,
    hasDesktopImage
  }
}