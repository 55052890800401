<script setup>
import DOMPurify from 'dompurify'
const props = defineProps({
  content: Object
})

const cleanHtml = ref('')
onMounted(() => {
  cleanHtml.value = DOMPurify.sanitize(props.content.html)
})
</script>

<template>
  <div class="mb-16 overflow-x-auto max-w-[90vw]" v-html="cleanHtml"></div>
</template>

<style scoped>
:deep(th){
  @apply bg-gray-100 text-gray-700 font-semibold text-left sm:p-4;
}
 :deep(td){
  @apply border-b border-gray-100 sm:p-3 px-2;
}
 :deep(td:nth-child(1)){
  @apply font-semibold;
}
 :deep(a){
  @apply text-primary underline;
}

</style>