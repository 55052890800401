<script setup>
import Image from '@/components/Image.vue'
const props = defineProps({
  content: Object
})

const {
  footerBgClasses,
  textClass,
  buttonClasses,
  textAlignmentClasses,
  flexDirection,
  imagePositionClasses,
  textPositionJustificationClass,
  hasMobileImage,
  hasDesktopImage,
  imageMobile,
  imageDesktop
} = useFooterCTA(props.content)

</script>

<template>
  <section :class="`2xl:mt-28 xl:mt-32 lg:mt-28 mt-16 w-full -mb-12 ${footerBgClasses}`">
    <div class="lg:main-container">
      <div :class="`lg:w-3/4 relative flex flex-col items-center pt-4 ${flexDirection} ${textPositionJustificationClass}`">
        <div v-if="content.kicker" :class="`${textClass} text-sm mb-4`">
          {{ content.kicker }}
        </div>
        <div v-if="hasMobileImage || hasDesktopImage">
          <Image v-if="hasMobileImage" :content="imageMobile" class-list=""
          :class="`${imagePositionClasses} sm:w-2/3 lg:hidden`" />
          <Image v-if="hasDesktopImage" :content="imageDesktop" class-list=""
          :class="`${imagePositionClasses} lg:absolute lg:w-3/5 2xl:w-2/5 hidden lg:flex`" />
        </div>
        <div :class="`flex flex-col text-center pb-6 lg:w-2/5 2xl:w-3/5 ${textAlignmentClasses}`">
          <MDC v-if="content.ctaBody"
            :class="`${textClass} prose prose-h2:font-family-raleway prose-h2:font-semibold prose-h2:mb-2 mb-6 text-center ${textAlignmentClasses} leading-6 text-base sm:text-lg lg:text-xl`"
            :value="content.ctaBody" />
          <div class="items-center">
            <a v-if="content.link" :href="content.link"
              :class="`${buttonClasses} flex-wrap cta-link py-2 px-4 my-3 rounded-3xl lg:text-lg font-extrabold border border-x-2 border-y-1`">
              {{ content.button_text }}
            </a>
          </div>
        </div>
      </div>
    </div>
    
  </section>
</template>


<style lang="scss" scoped>
.cta-link {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
</style>