<script setup>
const props = defineProps({
  url: {
    type: String,
    required: true
  },
  alt: {
    type: String,
    required: true
  }
})

function onClick () {
  window.open(props.url, '', 'toolbar=0, status=0, width=900, height=500')
  return false
}
</script>

<template>
  <a class="" href="#" :alt="props.alt" @click.prevent="onClick()">
    <slot />
  </a>
</template>