<script setup>
import Image from '@/components/Image.vue'
const props = defineProps({
  content: Object
})

const { bgClasses, textClass, buttonClasses, textAlignmentClasses, flexDirection, imageOrder, textOrder, imageAnchor, imageMobile, imageDesktop, hasMobileImage, hasDesktopImage  } = useCTA(props.content)

</script>

<template>
  <div :class="`flex flex-col items-center pt-4 px-6 mb-8 sm:mb-12 ${bgClasses} ${flexDirection}`">
    <div v-if="content.kicker" :class="`${textClass} text-sm mb-4`">
      {{ content.kicker }}
    </div>
    <div v-if="hasMobileImage || hasDesktopImage" :class="`${imageOrder}`">
      <Image v-if="hasMobileImage" :content="imageMobile" class-list="mb-5 lg:mb-0"
      :class="`${imageAnchor} lg:hidden`" /> 
      <Image v-if="hasDesktopImage" :content="imageDesktop" class-list="mb-5 lg:mb-0"
      :class="`${imageAnchor} hidden lg:flex`" />
    </div>
    <div :class="`flex flex-col text-center pb-6 ${textAlignmentClasses} ${textOrder}`">
      <MDC v-if="content.ctaBody"
        :class="`${textClass} prose prose-h2:font-family-raleway prose-h3:font-family-raleway prose-h2:font-semibold prose-h2:mb-4 prose-h3:font-bold prose-h3:lg:leading-9 mb-6 text-center ${textAlignmentClasses} leading-6 text-base sm:text-lg lg:leading-7 lg:text-xl xl:text-2xl`"
        :value="content.ctaBody" />
      <div class="items-center">
        <a v-if="content.link" :href="content.link"
          :class="`${buttonClasses} flex-wrap cta-link py-2 px-4 my-3 rounded-3xl lg:text-lg font-extrabold`">
          {{ content.button_text }}
        </a>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.cta-link {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
</style>