<script setup>
import { useStore } from '@/store/index.js'
import CTA from '@/components/CTA.vue'
import FooterCTA from '@/components/FooterCTA.vue'
import ArticleParagraph from '@/components/ArticleParagraph.vue'
import ArticlePicture from '@/components/ArticlePicture.vue'
import ArticleTable from '@/components/ArticleTable.vue'
import ArticleFootNote from '@/components/ArticleFootNote.vue'
import Image from '@/components/Image.vue'
import Toc from '@/components/Toc.vue'
import ShareButton from '@/components/ShareButton.vue'
import StopwatchIcon from '@/components/icons/StopwatchIcon.vue'
import ChevronLeftIcon from '@/components/icons/ChevronLeftIcon.vue'
import FacebookIcon from '@/components/icons/FacebookIcon.vue'
import XTwitterIcon from '@/components/icons/XTwitterIcon.vue'
import LinkedInIcon from '@/components/icons/LinkedInIcon.vue'
import {
  ARTICLE_PARAGRAPH,
  CALL_TO_ACTION,
  PICTURE,
  PARAGRAPH_PREFIX,
  TABLE,
  FOOTNOTE
} from '@/utils/constants'
import { getReadingTime } from '@/utils/text.js'

const store = useStore()
const route = useRoute()
const slug = route.params.slug
const article = store.articleBySlug(slug)
if (!article) {
  throw createError({ statusCode: 404, statusMessage: 'Page not found', fatal: true })
}

const title = article.attributes.title

const coverObj = article.attributes.cover.data.attributes

const { twitterShareUrl, facebookShareUrl, linkedInShareUrl } = useShareUrl(article)

function mapTypeToComp (type) {
const map = {
    [ARTICLE_PARAGRAPH] : ArticleParagraph,
    [CALL_TO_ACTION] : CTA,
    [PICTURE] : ArticlePicture,
    [TABLE] : ArticleTable,
    [FOOTNOTE]: ArticleFootNote,
  }
  return map[type]
}

function getTocOptions () {
  return article.attributes.content
    .filter(c => c.__typename === ARTICLE_PARAGRAPH && !!c.title)
    .map(c => ({ label: c.title, slug: PARAGRAPH_PREFIX + c.id }))
}

const readingTime = computed (() => {
  const time = getReadingTime(article.attributes.content)
  return `${time} ${ time > 1 ? 'minuti' : 'minuto'} di lettura`
})

const tagList = computed(() => {
 return article.attributes.tag.data.map(t => ({ name: t.attributes.name, slug:t.attributes.slug }))   
})

const footerCTA = article.attributes.footerCTA

useMnetBlogSeo(article)
</script>

<template>
  <section class="main-container">
    <NuxtLink to="/blog/">
      <p class="text-[15px] font-semibold inline-flex items-center">
        <ChevronLeftIcon width="24px" />
        <span>Torna alla home</span>
      </p>
    </NuxtLink>
  </section>
  <section class="main-container flex px-0">
    <Image :content="coverObj" class-list="object-cover cover w-full" class="sm:w-3/4" :preload="true" />
  </section>
  <section class="main-container flex mt-4 sm:mt-8">
    <div class="sm:w-3/4 flex flex-col">
      <div class="mb-4">
        <h1 class="font-raleway text-[28px] sm:text-[32px] lg:text-[38px] mb-2 font-bold">{{ title }}</h1>
      </div>
      <div class="sm:hidden py-6">
        <Toc :options="getTocOptions()" v-if="article.attributes.useTableOfContents"/>
      </div>
      <div class="flex w-full flex-wrap justify-between my-6 md:my-8 lg:text-lg xl:text-xl gap-y-4 gap-x-8 items-center">
        <span class="flex">
          <StopwatchIcon class="text-primary mr-2"/>
          {{ readingTime }}
        </span>
        <span v-if="tagList.length > 0">
          TAG:
          <NuxtLink :to="`/blog/tags/${tag.slug}/`" v-for="(tag, index) in tagList" :key="tag.slug" class="text-primary">
            <span class="underline">{{ tag.name }}</span>
            <span v-if="(index + 1) < tagList.length">,&nbsp;</span>
          </NuxtLink>
        </span>
      </div>
      <template v-for="content in article.attributes.content" :key="content.id">
        <component :is="mapTypeToComp(content.__typename)" :content="content"></component>
      </template>
      <div class="flex flex-row self-end w-auto gap-x-2 items-center mt-6 py-2 px-4 justify-end">
        Condividi su:
        <ShareButton :url="linkedInShareUrl" :alt="'LinkedIn'">
          <LinkedInIcon class="mx-1 inline-block fill-dark hover:fill-primary" />
        </ShareButton>
        <ShareButton :url="twitterShareUrl" :alt="'Twitter'">
          <XTwitterIcon class="mx-1 inline-block fill-dark hover:fill-primary" />
        </ShareButton>
        <ShareButton :url="facebookShareUrl" :alt="'Facebook'">
          <FacebookIcon class="mx-1 inline-block fill-dark hover:fill-primary" />
        </ShareButton>
      </div>
    </div>
    <div class="sm:w-1/4 mb-6 hidden sm:block pl-8 lg:pl-20">
      <Toc :options="getTocOptions()" v-if="article.attributes.useTableOfContents"/>
    </div>
  </section>
  <FooterCTA v-if="footerCTA" :content="footerCTA" />
</template>