
<script setup>
import Image from '@/components/Image.vue'

const props = defineProps({
  content: Object
})

const coverObj = props.content.image.data.attributes
const link = props.content.link ? props.content.link : undefined
const computedLink = computed(() => {
  if (link) {
    const trimmedLink = link.trim()
    return trimmedLink.startsWith('http')
      ? trimmedLink
      : `https://${trimmedLink}`
  }
  return null
})
</script>

<template>
  <figure class="mb-8">
    <a v-if="computedLink" :href="computedLink">
      <Image class-list="mx-auto lg:max-w-[700px]" :content="coverObj"></Image>
    </a>
    <Image class-list="mx-auto lg:max-w-[700px]" :content="coverObj" v-else></Image>
    <figcaption class="text-base text-center italic my-2 lg:leading-7 lg:text-xl">
      <MDC :value="content.caption" v-if="!!content.caption" />
    </figcaption>
  </figure>
</template>